import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require('./config')

export type NavigationRouteParamsType = {
    serviceCaller: string,
    connectCallback: () => void
}
export interface Props {
    // navigation: NavigationScreenProps
    navigation: any
}

interface S {
    isMetaConnected: boolean,
    isGoogleConnected: boolean,
    isSendinBlueConnected: boolean,
    showErrorModal: boolean,
    errorMessage: string,
    isAddIntegration: boolean
}

interface SS { }

export interface Props {
    navigation: any;
    id?: string;
    classes?: any;
    backToStartIntegration: any
}

export default class StartIntegrationController extends BlockComponent<Props, S, SS> {

    userSessionData: any;
    userToken: any;

    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)]
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.userSessionData = sessionStorage.getItem("userData");
        this.userToken = JSON.parse(this.userSessionData);

        this.state = {
            isMetaConnected: false,
            isGoogleConnected: false,
            isSendinBlueConnected: false,
            showErrorModal: false,
            errorMessage: '',
            isAddIntegration: false
        }
    }


    addIntegration = () => {
        this.setState({ isAddIntegration: true })
    }

    backToStartIntegration = () => {
        this.setState({ isAddIntegration: false })
    }

    skipToProfile = () => {
        this.props.navigation.navigate("CustomisableUserProfiles");
    }

}

