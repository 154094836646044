import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Popover,
  Typography,
  Card,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import QuickChatController, {
  Props,
  configJSON,
} from "./QuickChatController.web";
import { liveChatCustomStyles } from "../../../components/src/LiveChatStyles.web";
import { withStyles } from "@material-ui/core/styles";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import {
  DoubleTick,
  close,
  doubleTickGray,
  externalImage,
  minus,
  noQuickChat,
  quickChatIcon,
} from "./assets";
import InfiniteScroll from "react-infinite-scroll-component";
import { Chat, GroupedMessages } from "../../../components/src/ReusableEnums";
import ChatImagePreview from "../../../components/src/ChatImagePreview.web";
import NewChatModal from "../../../components/src/NewChatModal.web";
import { formatChatDate, formatLiveChatTime, renderContent, renderMedia } from "../../../components/src/ReusableFunctions";
// Customizable Area End

export class QuickChat extends QuickChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      ...this.state,
      isChatCollapsed: false,
    };
    // Customizable Area End
  }

  // Customizable Area Start
  toggleChatCollapse = () => {
    this.setState((prevState) => ({
      isChatCollapsed: !prevState.isChatCollapsed,
    }));
  };
 renderSigleChatModal = () => {
  const { classes } = this.props;
  const { openSingleChat ,quickItemData, isChatCollapsed } = this.state;
    return (
      <Popover
        open={Boolean(openSingleChat)}
        anchorEl={openSingleChat}
        onClose={this.closeSingleChatEvent}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        classes={{ paper: `${classes.sinlgeChatModal}` }}
      >
        <Box>
          <Box className={classes.quickChatHeader}>
            <Box className="etoh-header-quick-chat">
              {renderContent(quickItemData.attributes.chat_type,quickItemData.attributes.name.charAt(0).toUpperCase())}
              <Typography className="user-name">
                {quickItemData.attributes.name}
              </Typography>
            </Box>
            <Box className="single-chat-icons">
              <Box
                className="close-icon"
                onClick={this.toggleChatCollapse}
                data-test-id="isChatCollapsed"

              >
                <img src={minus} alt="minus" />
              </Box>
              <Box
                className="close-icon"
                onClick={this.closeSingleChatEvent}
                data-test-id="closeNewChat"
              >
                <img src={close} alt="closeGray" />
              </Box>
            </Box>
          </Box>
          {!isChatCollapsed && (
         <>
          <Box id="parentBoxID" style={{ maxHeight: 700, width: 350, overflow: "auto" }}>
          <InfiniteScroll
            dataLength={this.state.singleMessage.length}          
            next={this.fetchMoreDataEvent}
            hasMore={this.state.hasNextPageEvent}
            loader={
              <Box className="loader" key="loader">
                <Typography>
                  {this.translateQChat(`${configJSON.loadingTxt}`)}
                </Typography>
              </Box>
            }            
            scrollableTarget="parentBoxID"
            data-test-id="InfiniteScroll"
          >
            <Box className="drawerContent">
              <Box className="profileQuickChatBlockOuter">
                <Box className="chat-block-wrapper">
                  {this.state.singleMessage?.map((group: GroupedMessages) => (
                    <Box key={group.date}>
                      <Typography className="date-message">
                        {group.date}
                      </Typography>
                      {group.messages.map((item) => (
                        <Box key={item.id}>
                          <Box
                            className={
                              item.attributes.receiver_type === "AdminUser"
                                ? "chat-text-content-sender"
                                : "chat-text-content-reciever"
                            }
                          >
                            <Box className="right-content-quick">
                              {item.attributes.message && (
                                <Typography className="help-message-quick">
                                  {item.attributes.media_file &&
                                renderMedia(
                                  item.attributes.media_file,
                                  item.attributes.media_type
                                )}
                                {item.attributes.message}
                                </Typography>
                              )}
                             
                              {item.attributes.media_file &&                          
                              <Typography className="help-message-quick">
                                    {renderMedia(
                                    item.attributes.media_file,
                                    item.attributes.media_type
                                    )}
                               </Typography>
                              }                               
                              <Typography className="tick-time-quick">
                                <img
                                  className="img-quick"
                                  alt="doubleTickGray"
                                  src={doubleTickGray}
                                ></img>
                                {formatLiveChatTime(item.attributes.created_at)}                               
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  ))}
                  <div ref={this.chatEndRef} />                   
                </Box>
              </Box>
            </Box>
          </InfiniteScroll>                          
          </Box>
          {this.state.chatTxtMessage && (
            <Box className="show-input-message">
              <Typography className="quick-msg-display">
                {this.state.chatTxtMessage}
              </Typography>
            </Box>
          )}
          <Box className="send-quick-messge-area">
            <ChatImagePreview
              handleCloseAttachment={this.handleCloseAttachmentEvent}
              previews={this.state.preview}
              filesUploaded={this.state.fileUploaded}
              translateChat={this.translateQChat}
              chatTxtMessage={this.state.chatTxtMessage}
              handleAllInputChange={this.handleChatInputChange}
              onBrowse={this.onBrowseEvent}
              handleKeyDown={this.handleKeyDown}
              formRef={this.formRef}
              fileRef={this.fileRef}
              handleSubmitMessage={this.handleSubmitMessage}
              createExternalAndInternalChatMessgaeEvent={
                this.createExternalAndInternalChatMessgaeEvent
              }
              chatType={quickItemData.attributes.chat_type}
              handleOnDrop={this.handleOnDropEvent}
              messageId={this.state.messageId}
              data-test-id="ChatImagePreview"
            />
          </Box>
          </>
        )}
        </Box>
      </Popover>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, anchorQuickChatEl, handleQuickChatPopoverClose } =
      this.props;
    const { quickChatData, openSingleChat } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Popover
          open={Boolean(anchorQuickChatEl)}
          anchorEl={anchorQuickChatEl}
          onClose={handleQuickChatPopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{ paper: `${classes.quickChatBox}` }}
        >
          <Card>
            <Box className="quick-chat-container">
              <Box>
                <Typography variant="h6" className="title">
                  {this.translateQChat(`${configJSON.chatConversationsTxt}`)}
                  <img
                    className="icon"
                    src={quickChatIcon}
                    alt="quickChatIcon"
                  />
                </Typography>
                <Typography variant="body2" className="sub-title">
                  {this.translateQChat(`${configJSON.chatSubtext}`)}
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="text"
                  className="btn-new-chat"
                  data-test-id="openNewChatEvent"
                  onClick={this.openNewChatEvent}
                >
                  + {this.translateQChat(`${configJSON.newChatBtn}`)}
                </Button>
              </Box>
              <Box></Box>
            </Box>
            <Box id="parentBox" className="quickChatParentBox">
              {quickChatData && quickChatData.length > 0 ? (
                <InfiniteScroll
                  dataLength={quickChatData.length}
                  next={this.fetchMoreData}
                  hasMore={false}
                  loader={
                    <Box className="loader" key="loader">
                      <Typography>
                        {this.translateQChat(`${configJSON.loadingTxt}`)}
                      </Typography>
                    </Box>
                  }
                  className="list"
                  scrollableTarget="parentBox"
                >
                  <Box className="drawerContent">
                    <Box className="profileBlockOuter">
                      <Box>
                        <Box className="chat-block-wrapper">
                          {quickChatData.map((item: Chat) => {
                            const chatName = item.attributes.name.split(" ");
                            const nameInitials = chatName
                              ?.map((item: string) =>
                                item.charAt(0).toUpperCase()
                              )
                              .join("");
                            return (
                              <Box
                                className="etoh-header-chat"
                                key={item.id}
                                data-test-id={`openSingleChatEvent-${item.id}`}
                                onClick={(
                                  event: React.MouseEvent<HTMLElement>
                                ) => this.openSingleChatEvent(event, item)}
                              >
                                {item.attributes.chat_type === "external" ? (
                                  <Box className="etoh-name">                                   
                                    <img
                                      src={externalImage}
                                      alt="brows"
                                    ></img>
                                  </Box>
                                ) : renderContent(item.attributes.chat_type,nameInitials)
                                }
                                <Box className="etoh-content">
                                  <Typography className="support-txt">
                                    {item.attributes.name}
                                  </Typography>
                                  <Typography className="support-sub-txt">
                                    {item.attributes.last_message.data &&
                                      item.attributes.last_message.data
                                        .attributes.message}
                                  </Typography>
                                </Box>
                                <Box className="time-content">
                                  <Typography className="chat-time">
                                    {item.attributes.last_message.data &&
                                    formatChatDate(item.attributes.last_message.data.attributes.created_at)}
                                  </Typography>
                                  {item.attributes.unread_count !== 0 ? (
                                     <Box className="chat-count">
                                     <Typography  className="chat-count-position">
                                        {item.attributes.unread_count}
                                      </Typography>
                                      </Box>  
                                  ) : (
                                    <img
                                      src={DoubleTick}
                                      className="chat-double-tick"
                                      alt="double tick"
                                    />
                                  )}
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </InfiniteScroll>
              ) : (
                <Box className="default-chat">
                  <img className="image" src={noQuickChat} alt="noQuickChat" />
                  <Typography className="title">
                    {this.translateQChat(`${configJSON.defaultText}`)}
                  </Typography>
                  <Typography className="sub-title">
                    {this.translateQChat(`${configJSON.subText}`)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Card>
        </Popover>
        {openSingleChat && this.renderSigleChatModal()}
        {this.state.openQuickNewChat && (
          <NewChatModal
            modalOpen={this.state.openQuickNewChat}
            modalClose={this.closeNewChatQuickEvent}
            openExternalChat={this.state.openExternalQChat}
            openExternalChatEvent={this.openQuickExternalChatEvent}
            closeExternalChat={this.closeExternalChat}
            email={this.state.email}
            emailError={this.state.error.email}
            inviteMemberEvent={this.inviteMember}
            handleInviteMember={this.handleInviteMemberEvent}
            createExternalChatEvent={this.createExternalChatEvent}
            classes={classes}
            createExternalAndInternalChatEvent={
              this.createExternalAndInternalChatEvent
            }
            subscribedUserData={this.state.subscribedUserData}
            t={this.translateQChat}
            handleAllInputChange={this.handleChatInputChange}
            userSearch={this.state.userSearch}
            userSortBy={this.state.userSortBy}
            openEmailvarification={this.state.openEmailvarification}
            closeEmailVarification={this.closeEmailVarificationEvent}
            createEtohSupportChatEvent={this.createEtohSupportChatEvent}
            data-test-id="NewChatModal"
          />
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(liveChatCustomStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(QuickChat)
);
// Customizable Area End
