// Customizable Area Start
import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import ModalWeb from "./Modal.web";

interface CustomInputProps {
  modalOpen: any;
  modalClose: any;
  createUserAccount: any;
  checkBoxChecked: any;
  email: string;
  displayName: string;
  userGoogleImageURL: string;
  checkBoxErrors: string;
  isChecked: boolean;
  notYouHandler?: any;
  isGoogle?: boolean;
  t:any;
  getPrivacyPolicy?:(value : boolean)=>void ;
  termsPrivacyData?: string;
  setPrivacyNull?:()=> void;
  testdataid?: string;
}
const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const breakpointsFull = {
  breakpoints: createBreakpoints({
    values: BREAKPOINTS,
  }),
};
const useStyles = makeStyles((theme) => ({
  messageModal: {
    flex: "0 0 51%",
    maxWidth: "51%",
    padding: "0 15px",
    "& .auth-logo": {
      maxWidth: "162px",
      marginBottom: "90px",
    },
    "& .auth-inner": {
      paddingTop: "129px",
      "& .auth-left-row": {
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap",
        margin: "0 -10px",
        "& .col": {
          padding: "0 10px",
        },
        "& .col-left": {
          flex: "0 0 54%",
          maxWidth: "54%",
        },
        "& .col-right": {
          flex: "0 0 46%",
          maxWidth: "46%",
          paddingRight: "206px",
          "@media (max-width: 1536px)": {
            paddingRight: "95px",
          },
        },
      },
    },
    "& .auth-desc": {
      fontSize: "24px",
      lineHeight: "28px",
      color: "#000000",
      fontWeight: "400",
      marginBottom: "19px",
    },
    "& .auth-name": {
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0",
      color: "#94a3b8",
      fontWeight: "400",
    },
    "& .auth-img": {
      margin: "61px auto 0",
      maxWidth: "380px",
      width: "100%",
      height: "630px",
      overflow: "hidden",
      position: "relative",
      marginBottom: "-50px",
    },
    "& .copyright-txt": {
      fontSize: "12px",
      letterSpacing: "0",
      color: "#94a3b8",
      fontWeight: "400",
      fontFamily: "Expletus Sans",
      textAlign: "right",
      "& span": {
        color: "#2b2b2b",
      },
    },
  },
  primaryButton: {
    background: "#4ba3b7",
    border: "1px solid #4ba3b7",
    borderRadius: "7px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "19px",
    lineHeight: "23px",
    color: "#ffffff",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    textTransform: "unset",
    width: "100%",
    padding: "12px 10px",
    minHeight: "56px",
    marginBottom: "30px",
    transition: "all 0.5s ease-in-out",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "20px",
      padding: "8px 6px",
      minHeight: "46px",
    },
    "&:hover": {
      background: "#4ba3b7",
      color: "#ffffff",
    },
  },

  // modal start
  modalDialogBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 430,
    width: "100%",
    backgroundColor: "#ffffff",
    padding: "36px 40px 34px",
    borderRadius: "24px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      maxWidth: "90%",
    },
    "& .modal-heading": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "27px",
    },
    "& .modal-title": {
      fontSize: "22px",
      lineHeight: "26px",
      color: "#000000",
      letterSpacing: "0",
      fontFamily: "Expletus Sans",
    },
    "& .close-icon": {
      color: "rgba(43, 43, 43, 0.87)",
      lineHeight: "0",
    },
    "& .bottom-link": {
      textAlign: "center",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0",
      color: "#2B2B2B",
      fontWeight: "500",
      "& .modal-link": {
        marginLeft: "3px",
      },
      "& a": {
        color: "#4BA3B7",
        marginLeft: "3px",
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
      color: "red",
    },
    "& .primary-btn": {
      minHeight: "48px",
      fontSize: "14px",
      lineHeight: "19px",
      marginBottom: "23px",
    },
  },
  modalDialog: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(57,57,57,0.502)",
    },
  },
  modalLink: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0",
    color: "#4ba3b7",
    fontWeight: 500,
    transition: "all 0.5s ease-in-out",
    textTransform: "capitalize",
    textDecoration: "none",
  },
  messageList: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "11px",
    "& .message-detail": {
      flex: "1",
      display: "flex",
      alignItems: "center",
      "& .message-icon": {
        overflow: "hidden",
        background: "#F2F8FC",
        width: "48px",
        height: "48px",
        borderRadius: "100%",
        display: "flex",
        justifyContent: " center",
        alignItems: " center",
        letterSpacing: "-0.394867px",
        color: "rgba(43, 43, 43, 0.87)",
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: "700",
        marginRight: "13px",
      },

      "& .message-name": {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "19px",
        color: "#000000",
        marginBottom: "3px",
        fontFamily: "Expletus Sans",
      },
      "& .message-id": {
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "16px",
        color: "rgba(43, 43, 43, 0.87)",
      },
    },
    "& .message-right": {
      marginkeft: "10px",
    },
  },
  checkboxControl: {
    marginBottom: "6px",
    "@media (max-width: 480x)": {
      marginBottom: "10px",
    },
    "& .MuiFormControlLabel-label": {
      color: "#000000",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "17px",
    },
    "& .MuiCheckbox-root": {
      color: "#94A3B8",
      "&.Mui-checked": {
        color: "#4ba3b7",
      },
    },
    "& p": {
      margin: "0",
    },
  },
}));

const CustomModalWeb = (props: CustomInputProps) => {
  const {
    modalOpen,
    modalClose,
    createUserAccount,
    checkBoxChecked,
    checkBoxErrors,
    email,
    displayName,
    userGoogleImageURL,
    isChecked,
    notYouHandler,
    isGoogle,
    t,
    getPrivacyPolicy,
    termsPrivacyData,
    setPrivacyNull
  } = props;
  const [privacyModal, setprivacyModal] = useState(false);
  const [isPrivacyModal, setIsPrivacyModal] = useState(false);
  const handleModalTerms = () => {
    setprivacyModal(!privacyModal);
    setIsPrivacyModal(false);
    if(getPrivacyPolicy) {getPrivacyPolicy(false)}
  };
  const handleModalPrivacy = () => {
    setprivacyModal(!privacyModal);
    setIsPrivacyModal(true);
    if(getPrivacyPolicy) {getPrivacyPolicy(true)}
  };
  const handleCloseModal = () => {
    setprivacyModal(false);
  };
  const getFirstLetterOfWord = (value: string) => {
    return value.charAt(0).toUpperCase();
  };
  const getInitials = (value: string) => {
    let valueArray = value.split(" ");
    if (valueArray.length > 1) {
      return (
        getFirstLetterOfWord(valueArray[0]) +
        getFirstLetterOfWord(valueArray[1])
      );
    } else {
      getFirstLetterOfWord(valueArray[0]);
    }
  };
  const iconImage =
    // google image
    //  userGoogleImageURL ? (
    //   <img src={userGoogleImageURL} />
    // ) :
    displayName ? getInitials(displayName) : getFirstLetterOfWord(email);
  const classes = useStyles();
  return (
    <Box className={classes.messageModal}>
      <Modal
        open={modalOpen}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
      >
        <Box className={classes.modalDialogBox}>
          <Box className="modal-heading">
            <Typography className="modal-title" component="h2">
              Join EtOH Suite
            </Typography>
            <Link
              to="#"
              className="close-icon"
              onClick={modalClose}
              data-testid="modalCloseButton"
            >
              <CloseIcon />
            </Link>
          </Box>
          <Box className="modal-description">
            <Box className={classes.messageList}>
              <Box className="message-detail">
                {isGoogle && <Box className="message-icon">{iconImage}</Box>}
                <Box className="message-info">
                  {displayName && (
                    <Box className="message-name">{displayName}</Box>
                  )}

                  <Box className="message-id">{email}</Box>
                </Box>
              </Box>
              <Box className="message-right">
                <Link
                  title="Not you?"
                  to="#"
                  onClick={modalClose}
                  className={classes.modalLink}
                >
                  Not you?
                </Link>
              </Box>
            </Box>
            <Box className={classes.checkboxControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={checkBoxChecked}
                    data-testid="checkboxPrivacy"
                    checked={isChecked}
                  />
                }
                label={
                  <p>
                    I agree to the{" "}
                    <Link
                      to="#"
                      className={classes.modalLink}
                      onClick={handleModalTerms}
                    >
                      Terms of Use
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="#"
                      className={classes.modalLink}
                      onClick={handleModalPrivacy}
                    >
                      Privacy Policy
                    </Link>
                  </p>
                }
              />
              {privacyModal && (
                <ModalWeb
                  modalOpen={privacyModal}
                  modalClose={handleCloseModal}
                  termsPrivacyData = {termsPrivacyData}
                  modalType="privacyPolicy"
                  setPrivacyNull={setPrivacyNull}
                  // modalHeading="Terms of Use"
                  modalHeading={
                    isPrivacyModal ? "Privacy Policy" : "Terms of Use"
                  }
                />
              )}
              {checkBoxErrors && (
                <FormHelperText>{checkBoxErrors}</FormHelperText>
              )}
            </Box>
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              onClick={createUserAccount}
              title="Create account"
            >
              Create account
            </Button>
            <Box className="bottom-link">
              Have an account?
              <Link to="/login" onClick={modalClose} className={classes.modalLink}>
                Login
              </Link>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CustomModalWeb;
// Customizable Area End
