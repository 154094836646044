// Customizable Area Start
import React from "react";

import { Box, Button, Typography, CardMedia, Popover , Modal , Drawer,} from "@material-ui/core";

import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { contactListEmpty, close, multipleAccount, menu } from "./assets";
import { customStyles } from "./ContactsListStyles.web";
import { withStyles } from "@material-ui/core/styles";


import GroupListController, { Props } from "./GroupListController.web";
import Groups from "./components/Groups.web";
import GroupContacts from "./components/GroupContacts.web";
import DeleteGroup from "./DeleteContact.web";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import WebLoader from "../../../components/src/WebLoader.web";
import { configJSON } from "./ContactsListController.web";
import CreateGroup from "../../../components/src/CreateGroup.web";
import BackArrow from "@material-ui/icons/ArrowBack";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import CustomMarketingSidebar from "../../../components/src/CustomMarketingSidebar.web";

export class GroupList extends GroupListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes , isMobile } = this.props;

    return (
      <Box className={classes.mainWrapper}>
        {this.state.isLoading && <WebLoader />}
        <GlobalHeaderWeb pageLink={this.state.pageLink} />
        <Box className="mobile-heading">
                <Box className="back-btn-wrap">
                <BackArrow style={{color:"#2B2B2B"}} onClick={this.handleBackBtnEvent} />
                </Box>
                <Typography className="heading" variant="h1">
                    {this.t(`${configJSON.groupsTxt}`)}
                </Typography>
                <Box
                    className="marketing-menu"
                    data-test-id="marketingmenubar"
                    onClick={this.toggleMenuDrawerEvent}
                >
                    <img src={menu} alt="menu" />
                </Box>
            </Box>
        <Box className="page-wrapper">
        <Box className={classes.contactdrawer}>
              <Drawer
                classes={{
                  root: classes.campaignRootWrapper,
                  paper: classes.contactPaper,
                }}
                variant={isMobile ? "temporary" : "permanent"}
                open={isMobile ? this.state.isMenuDrawerOpenedEvent : true}
              >
                <CustomMarketingSidebar
                  toggleMenu={this.closeMenuDrawerEvent} 
                  groupsCount={this.state.groups.length}                 
                  t={this.t}
                />
              </Drawer>
            </Box>
          <Box className="main-content">
            {this.state.groups.length > 0 ? (
              <Groups
                classes={classes}
                openCreateContact={this.handleOpenCreateContact}
                closeCreateContact={this.handleCloseCreateContact}
                searchValue={this.state.searchKeyword}
                handleSearch={this.handleSearch}
                contactGroups={this.state.groups}
                anchorEl={this.state.groupAnchor}
                closePopover={this.handleCloseGroupPopover}
                openPopover={this.handleOpenGroupPopover}
                showAddDeleteInGroup={this.deleteGroupEvent}
                showAddRenameInGroup={this.openRenameGroup}
                closeDeleteInGroup={this.closeDeleteModal}
                handleSortGroup={this.handleSortGroup}
                openEditGroup={this.openEditGroup}
                onOpenViewGroup={this.onOpenViewGroup}
                isGroupSearchingSorting={this.state.isSearchingSorting}
                searcheSortGroupData={this.state.searcheSortGroupData} 
                t={this.t}             
                data-test-id="groupsList"
              />
            ) : (
              <Box className="empty-box">
                <CardMedia
                  component="img"
                  className="img-contact-book"
                  image={contactListEmpty}
                />
                <Box className="box-empty-right">
                  <Typography className="txt-no-contact-title">                    
                    {this.t(`${configJSON.groupNotFound}`)}
                  </Typography>
                  <Typography className="txt-no-contact-sub-title">
                    {this.t(`${configJSON.groupDefaultMsg}`)}
                  </Typography>
                  <Button onClick={this.handleOpenCreateContact}>+ {this.t(`${configJSON.createGroup}`)}</Button>
                </Box>
              </Box>
            )}

            {this.state.floatCreateContact && (
              <Box className="btn-minimize-create-contact">
                <CardMedia
                  component="img"
                  src={multipleAccount}
                  className="expand-icon"
                  onClick={this.handleOpenCreateContact}
                  data-test-id="maximize-group-popover"
                />
                <Typography className="create-txt" onClick={this.handleOpenCreateContact}>{this.t(`${this.state.groupName}`)}</Typography>
                <Button onClick={this.closeFloatCreateContact} data-test-id="closeFloatCreateContact">
                  <CardMedia
                    component="img"
                    src={close}
                    className="close-icon"
                  />
                </Button>
              </Box>
            )}

            <Box className={this.state.groups.length > 0 ? "create-contact-wrapper" : "create-contact-wrapper-hide"}>
              <Button
                className="btn-create-contact"
                onClick={this.handleOpenCreateContact}
                data-test-id="main-create-group"
              >
                + {this.t(`${configJSON.createGroup}`)}
              </Button>
              <Popover
                open={
                  this.state.createContactPopoverShown &&
                  Boolean(this.state.anchorEl)
                }
                anchorEl={this.state.anchorEl}
                onClose={this.handleCloseCreateContact}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                classes={{ paper: `${classes.createContactBox}` }}
                style={{ bottom: "100px" }}
                onKeyDown={(event) => {this.handleEscCreateGroup(event)}}
                data-test-id="create-group-popover"
              >
                <CreateGroup
                  classes={classes}
                  handleCloseDialog={this.handleCloseCreateContact}
                  handleMinimizeCreateGroup={this.openFloatCreateContact}
                  groupName={this.state.groupName}
                  groupError={this.state.error.groupName}
                  handleChangeGroupName={this.handleChangeGroupName}
                  handleChangeContact={this.handleChangeContact}
                  contactId={this.state.contactId}
                  openGroupsContact={this.handleOpenGroupContact}
                  createGroup={this.createGroup}
                  t={this.t}
                  data-test-id="createGroup"
                />
              </Popover>
            </Box>
            </Box>

          {this.state.isDeleteGroup && ( 
            <DeleteGroup
            modalOpen={this.state.delModalOpen}
            modalClose={this.closeDeleteModal}
            delContactDetails={this.state.delGroupDetails}
            deleteContactAction={this.deleteGroupAction}
            headding={this.t(`${configJSON.deleteGroupTxt}`)}
            cancleBtntxt={this.t(`${configJSON.cancel}`)}
            deleteBtntxt={this.t(`${configJSON.delete}`)}
            confirmationMsg={this.t(`${configJSON.deleteTxtMsgGroup}`)}
            text="group"
            data-test-id="deleteGroupAction"
            />
          )}
          {this.state.isRenameGroup && (<Modal
              open={this.state.isRenameGroup}
              onClose={this.closeRenameGroup}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className={classes.modalBoxCenter}
              data-test-id="isRenameAction"
            >
          <Box className={classes.removeGroupsModal}>
            <Box className="modal-heading">
              <Typography className="title-txt">{this.t(`${configJSON.renameGroupText}`)}</Typography>
              <Box className="heading-right">
                <Link to="#" className="heading-icon" onClick={this.closeRenameGroup}>
                  <CloseIcon className="close-icon" />
                </Link>
              </Box>
            </Box>      
            <CustomInputWeb
              type="text"
              label={this.t(`${configJSON.groupName}`)}
              value={this.state.groupNameRename}
              name="groupNameRename"
              onChange={(e: any) =>
                      this.handleChangeGroupNameRename(e.target.value)
              }
              errors={this.state.groupError.groupNameRename}
              rows={4}
              data-test-id="groupName"
              />
              <Button onClick={this.renameGroupEvent}  data-test-id="renameGroupEvent" className="rename-group-button">{this.t(`${configJSON.renameGroupText}`)}</Button>
            </Box>
          </Modal>)}

          <GroupContacts
            classes={classes}
            modalOpen = {this.state.showGroupsContact}
            modalClose={this.handleCloseGroupContact}
            searchContactKeyword={this.state.searchContactKeyword}
            handleSearchContact={this.handleSearchContact}
            contacts={this.state.contactListData}
            isSearching={this.state.isContactSearching}
            isSorting={this.state.isContactSorting}
            handleSortContact={this.handleSortContact}
            searchedContacts={this.state.searchedContacts}
            editGroupEvent={this.editGroupEvent}
            sortedContacts={this.state.sortedContacts}
            subTitle={this.t(`${configJSON.chooseContactInGroup}`)}
            t={this.t}
            data-test-id="groupContacts"
            />

            {this.state.isEditGroup&&<GroupContacts
            classes={classes}
            modalOpen = {this.state.isEditGroup}
            modalClose={this.closeEditGroup}
            searchContactKeyword={this.state.searchContactKeyword}
            handleSearchContact={this.handleSearchContact}
            contacts={this.state.contactListData}
            editGroupEvent={this.editGroupEvent}
            contactIds={this.state.editcontactIds}
            isSearching={this.state.isContactSearching}
            searchedContacts={this.state.searchedContacts}
            handleSortContact={this.handleSortContact}
            isSorting={this.state.isContactSorting}
            sortedContacts={this.state.sortedContacts}
            title={this.t(`${configJSON.editGroupTitle}`)}
            subTitle={this.t(`${configJSON.editSubTxt}`)}
            t={this.t}
            data-test-id="groupContactsedit"
            />}
        </Box>
      </Box>
    );
  }
}

export default withStyles(customStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(GroupList));
// Customizable Area End
