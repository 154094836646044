import React from "react";
// Customizable Area Start
import { Modal, Typography, Button, Box } from "@material-ui/core";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import { withStyles } from "@material-ui/core/styles";
import ShoppingCartOrdersController, {
  Props,
  configJSON,
} from "./ShoppingCartOrdersController.web";
import CloseIcon from "@material-ui/icons/Close";
import { etohShopStyle } from "../../../components/src/EtohShopStyleHelper.web";
import { card, defaltCart } from "./assets";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import CustomInputContent from "../../../components/src/CustomInput.web";
// Customizable Area End

export class ShoppingCartOrders extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, handleCloseCartEvent, isCartOpen } = this.props;
    const { cartData } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {cartData.length === 0 ? (
          <Modal
            open={isCartOpen}
            onClose={handleCloseCartEvent}
            className={classes.modalBoxCenter}
          >
            <div className={classes.mergeInfoGroupsModal}>
              <div className="header-container">
                <div className="modal-title-box">
                  <Typography className="title-txt">
                    {this.translationShopEvent(`${configJSON.cartTxt}`)}
                  </Typography>
                </div>
                <div className="heading-bar">
                  <CloseIcon
                    className="close-icon"
                    onClick={handleCloseCartEvent}
                  />
                </div>
              </div>
              <div className="default-cart">
                <img src={defaltCart} className="default-cart-img" alt="img" />
              </div>
              <Typography className="cart-empty-text">
                {this.translationShopEvent(`${configJSON.cartEmptyTxt}`)}
              </Typography>
              <Typography className="cart-empty-text">
                {this.translationShopEvent(`${configJSON.cartEmptySubTxt}`)}
              </Typography>
              <div className="default-cart">
                <Button
                  className={`primary-btn-2 ${classes.primaryButton}`}
                  onClick={this.handleViewShop}
                  data-test-id="handleViewShop"
                >
                  {this.translationShopEvent(`${configJSON.viewShopTxt}`)}
                </Button>
              </div>
              <div />
            </div>
          </Modal>
        ) : (
          <Modal
            open={isCartOpen}
            onClose={handleCloseCartEvent}
            className={classes.modalBoxCenter}
            data-test-id="shoping-cart-modal-open"
          >
            <div className={classes.mergeInfoGroupsModal}>
              <div className="header-container">
                <div className="modal-title-box">
                  <Typography className="title-txt">
                    {this.translationShopEvent(`${configJSON.cartTxt}`)}
                  </Typography>
                  <Typography className="sub-title-txt">
                    {this.translationShopEvent(`${configJSON.cartSubTxt}`)}
                  </Typography>
                </div>
                <div className="heading-bar">
                  <CloseIcon
                    className="close-icon"
                    onClick={handleCloseCartEvent}
                  />
                </div>
              </div>
              <div />
              <div className="card-container-flex">
                {cartData.map((item: any) => {
                  const { title, short_description, service_type, thumbnails, converted_price, converted_sale_price } =
                    item.attributes.bx_block_catalogue_service.data.attributes;
                  return (
                    <div key={item.id}>
                      <div className="card-container">
                        <img
                          src={
                            thumbnails?.length > 0 ? thumbnails[0].url : card
                          }
                          alt="img"
                          className="cart-img"
                        />
                        <div className="card-content">
                          <Typography className="cart-heading">
                            {title}
                          </Typography>
                          <Typography className="cart-sub-heading">
                            {short_description}
                          </Typography>
                          {service_type === "Subscription" ? (
                            <div className="price-container">
                              <Typography className="cart-price">
                                €{item.attributes.selected_sale_price}
                              </Typography>
                              <Typography className="cart-price-dash-text">
                                  €{item.attributes.selected_price}
                              </Typography>
                              <Typography className="cart-price-text">
                                {item.attributes.duration}
                              </Typography>
                            </div>
                          ) : (
                            <div className="price-container">
                              <Typography className="cart-price">
                                {converted_price}
                              </Typography>
                              <Typography className="cart-price-dash-text">
                                {converted_sale_price}
                              </Typography>
                            </div>
                          )}
                        </div>
                        <Box className="card-quantity-container">
                          {service_type === "Subscription" && (
                            <div>
                              <CustomInputContent
                                type="number"
                                onKeyDown={(e) => {
                                  if (["e", "E", "-"].includes(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                value={
                                  this.state.customQuantity[item.id] !==
                                  undefined
                                    ? this.state.customQuantity[item.id]
                                    : item.attributes.quantity
                                }
                                onChange={(e) =>
                                  this.handleCustomQuantityChange(item.id, e)
                                }
                                onBlur={() =>
                                  this.updateQuantityData(
                                    item.id,
                                    this.state.customQuantity[item.id]
                                  )
                                }
                                data-test-id={`update-quantity-${item.id}`}
                              />
                            </div>
                          )}
                          <div
                            data-test-id="deleteCartItems"
                            onClick={() => this.deleteCartItems(item.id)}
                          >
                            <Button
                              className={`secondary-btn ${classes.secondaryButtonn}`}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </Button>
                          </div>
                        </Box>
                      </div>
                      <div className="line" />
                    </div>
                  );
                })}
              </div>
              <Typography className="cart-subtotal-text">
                {this.translationShopEvent(`${configJSON.subtotalTxt}`)}&nbsp; (
                {this.state.cartData.length > 1
                  ? `${this.state.cartData.length} ${this.translationShopEvent(
                      `${configJSON.itemsTxtCapital}`
                    )}`
                  : `${this.state.cartData.length} ${this.translationShopEvent(
                      `${configJSON.itemTxt}`
                    )}`}
                ): €{this.state.totalPrice}
              </Typography>
              <div
                className={
                  this.state.oneTimeItemCount > 0
                    ? "cart-end-section"
                    : "cart-end-section-hide"
                }
              >
                <Typography className="cart-end-text">
                  {this.translationShopEvent(`${configJSON.itemsTxtCapital}`)}&nbsp;(
                  {this.state.oneTimeItemCount})
                </Typography>
                <Typography className="cart-end-price-text">
                  €{this.state.oneTimePrice}
                </Typography>
              </div>
              <div
                className={
                  this.state.subscriptionItemCount > 0
                    ? "cart-end-section"
                    : "cart-end-section-hide"
                }
              >
                <Typography className="cart-end-text">
                  {this.translationShopEvent(`${configJSON.subscriptionServiceTxt}`)}&nbsp;(
                  {this.state.subscriptionItemCount})
                </Typography>
                <Typography className="cart-end-price-text">
                  €{this.state.subscriptionPrice} per month
                </Typography>
              </div>
              <div className="note-section">
                <Typography className="note-section-txt">
                  {this.translationShopEvent(`${configJSON.noteTxt}`)} : &nbsp;
                </Typography>
                <Typography className="note-section-des-txt">
                  {this.translationShopEvent(`${configJSON.notesMsgTxt}`)}
                </Typography>
              </div>
              <div className="button-section">
                <Typography className="button-section-total-txt">
                  {this.translationShopEvent(`${configJSON.orderTotalTxt}`)}
                </Typography>
                <Typography className="button-section-price-txt">
                  €{this.state.totalPrice}&nbsp;
                  <span className="span">
                    {this.translationShopEvent(`${configJSON.ExclTaxtTxt}`)}
                  </span>
                </Typography>
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  data-test-id="pay-now"
                  onClick={this.makePayment}
                >
                  {this.translationShopEvent(`${configJSON.payNowTxt}`)} &nbsp;
                  <ArrowForwardOutlinedIcon />
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(etohShopStyle, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(ShoppingCartOrders)
);
// Customizable Area End
