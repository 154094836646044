// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./ContactsListStyles.web";
import { toast } from "react-toastify";
import { addToOtherGroup, multipleAccount, vector, warningIcon } from "./assets";
import { CardMedia } from "@material-ui/core";
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  pageLink: string;
  contacts: any;
  anchorEl: any;
  floatCreateContact: boolean;
  createContactPopoverShown: boolean;
  showBussinessInfo: boolean;
  showShippingInfo: boolean;
  showNotes: boolean;
  showFavoritesProduct: boolean;
  showGroup: boolean;
  searchKeyword: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  businessGroup: string;
  companyName: string;
  vatNumber: string;
  businessCategory: string;
  country: string;
  city: string;
  pinCode: string;
  address1: string;
  address2: string;
  notes: string;
  favoriteProducts: string[];
  group: string;

  isShowContactGroup: boolean;
  searchGroupKeyword: string;

  contactAnchor: any;
  selectedRowId: string;
  contactAnchorId: string;

  isShowEditContact: boolean;
  isEditingContact: boolean;
  openAddContcatMenu:any;
  groupDetails:any;
  contactDetails:any;
  groups:any;
  groupContactId:any;
  isDelete:any;
  deleteContactDetails: {
    firstName: string,
    lastName: string,
    id: string,
  };
  isRemoveGroup:boolean;
  renameContactDetails: {
    firstName: string,
    lastName: string,
    id: string,
    groupName:string,
  };
  sortValue:string;
  isSortFilter:boolean;
  sortType: string;
  isLoading:boolean;
  filterGroups: any;
  filterBusinessCategories: any;
  filterCompany: any;
  filterShippingCountry: any;
  sortedAndFilteredData:any;
  hasAnOpenDeal: boolean;
  businessCategoryList:any;
  hasFavoritesProduct: boolean;
  hasInteractions: boolean;
  hasNotes: boolean;
  anchorElMore: any;
  isAddAnExisting:boolean;
  searchedContacts:any;
  searchContactKeyword:string;
  isContactSearching:boolean;
  sortContactValue:string;
  isSorting:boolean;
  showMoreGroups: boolean;
  contactsData:any;
  addAnExistingData:any;
  anchorDeal: any;
  createDealPopoverOpen:boolean;
  selectContacts:boolean;
  openGroup:boolean;
  countryDataList:any;
}

interface SS {
  id: any;
}

export default class ViewContactsGroupController extends BlockComponent<
  Props,
  S,
  SS
> {
  getContactListRequestId: string = "";
  userSessionData: any;
  userToken: any;
  getSingleGroupRequestId:string="";
  getSingleContacDetailRequestId:string="";
  getGroupsRequestApiID:string="";
  addtogroupRequestApiId:string="";
  deleteContactGroupAPIID:string="";
  removeContactGroupAPIID:string="";
  getSortFilterRequestId:string="";
  getBusinessCategoryListRequestApiID:string="";
  getSearchContctsRequestId:string="";
  getAddAnExistingRequestId:string="";
  getAddAnExistingContactRequestId:string="";
  countryListDataApiCallID:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      pageLink: "",
      contacts: [{ name: "A" }],
      anchorEl: null,
      floatCreateContact: false,
      createContactPopoverShown: false,
      showBussinessInfo: false,
      showShippingInfo: false,
      showNotes: false,
      showFavoritesProduct: false,
      showGroup: false,
      searchKeyword: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      businessGroup: "",
      companyName: "",
      vatNumber: "",
      businessCategory: "",
      country: "",
      city: "",
      pinCode: "",
      address1: "",
      address2: "",
      notes: "",
      favoriteProducts: [],
      group: "",
      isShowContactGroup: false,
      searchGroupKeyword: "",

      contactAnchor: null,
      contactAnchorId: "",
      selectedRowId:"",

      isShowEditContact: false,
      isEditingContact: false,
      openAddContcatMenu:false,
      groupDetails:[],
      contactDetails:{},
      groups:[],
      groupContactId:"",
      isDelete:false,
      deleteContactDetails: {
        firstName: "",
        lastName: "",
        id: "",
      },
      isRemoveGroup:false,
      renameContactDetails: {
        firstName: "",
        lastName: "",
        id: "",
        groupName:"",
      },
      sortValue:"",
      sortType: "",
      isSortFilter:false,
      isLoading:false,
      filterGroups: [],
      filterBusinessCategories:[],
      filterCompany: [],
      filterShippingCountry: [],
      sortedAndFilteredData:[],
      businessCategoryList:[],
      hasInteractions: false,
      hasAnOpenDeal: false,
      hasNotes: false,
      hasFavoritesProduct: false,
      anchorElMore: null,
      isAddAnExisting:false,
      searchedContacts:[],
      searchContactKeyword:"",
      isContactSearching:false,
      sortContactValue:"",
      isSorting:false,
      showMoreGroups: false,
      contactsData:[],
      addAnExistingData:[],
      anchorDeal:"",
      createDealPopoverOpen:false,
      selectContacts:false,
      openGroup:false,
      countryDataList:[],
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.setState({ pageLink: window.location.pathname });
    this.getSingleGroup();
    this.getAllGroups();
    this.getBusinessCategoryList()
    this.getContactList()
    this.getAddExistingContcatList()
    const langnguage = localStorage.getItem("lang") ?? "en"; 
    await i18n.changeLanguage(langnguage); 
    this.countryListDataAPICallEvent()
  } 
  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.error) {
        this.createToastNotification(responseJson.error);
      } else {
        switch (apiRequestCallId) {
          case this.getSingleGroupRequestId:
            this.getSingleGroupResponse(responseJson.data);
            break; 
          case this.getSingleContacDetailRequestId:
            this.getSingleContacDetailResponse(responseJson);
            break; 
          case this.getGroupsRequestApiID:
           this.setState({groups:responseJson.data})
            break; 
          case this.addtogroupRequestApiId:           
            this.setAddGroupResponseAction(responseJson);
            break; 
          case this.deleteContactGroupAPIID:
            this.deleteContactResponse(responseJson);
            break; 
          case this.removeContactGroupAPIID:
            this.removeContactResponse(responseJson);
            break;  
          case this.getSortFilterRequestId:
            this.setState({isLoading: false})
            this.setState({sortedAndFilteredData:responseJson.data})
            break;
          case this.getBusinessCategoryListRequestApiID:
            this.setState({businessCategoryList: responseJson})
            break;
          case this.getContactListRequestId:
            this.setState({contacts: responseJson.data})
            break;
          case this.getSearchContctsRequestId:
            this.setState({isLoading: false})
            this.setState({searchedContacts:responseJson.data})
            break;
          case this.getAddAnExistingRequestId:
            this.setState({
              isLoading : false,
            })
            this.setState({addAnExistingData: responseJson.data})
            break;
          case this.getAddAnExistingContactRequestId:
            this.setState({
              isLoading: false
            })
            this.setAddExistingResponse(responseJson)
            break;  
          case this.countryListDataApiCallID:
            this.setState({countryDataList: responseJson.data});   
            break;          
          default:
            this.parseApiCatchErrorResponse(errorReponse);
            break;
        }
      }
    }
  }

  getSingleContacDetailResponse=(response:any)=>{
  this.setState({ contactDetails: response.data, isLoading : false }, () => {
    if(this.state.isEditingContact){
      this.setState({ isShowEditContact: true });
    }
  });
  }

  getSingleGroupResponse=(response:any)=>{
    this.setState({groupDetails:response})
  }

  setAddGroupResponseAction =(response:any)=>{
    if(response.message) {
      this.createToastNotificationSuccess("Added to Group",multipleAccount)
      this.getSingleGroup();
      this.handleCloseContactGroup();
    }
  }
  setAddExistingResponse=(response:any)=>{
    if(response.message) {
      this.createToastNotificationSuccess("Contact Added",addToOtherGroup)
      this.getSingleGroup();
      this.handleCloseAddAnExisting();
    }
  }
  deleteContactResponse = (response: any) => {
    this.createToastNotificationSuccesss(response.message)
    this.handleDeleteClose()
  }
  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }
  removeContactResponse = (response: any) => {
    if(response.message) {
      this.createToastNotificationSuccess("Removed from Group",vector)
      this.getSingleGroup();
      this.getAddExistingContcatList()
      this.handleRenameGroupClose()
    }   
  }
  countryListDataAPICallEvent = () => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.countryListDataApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webGetCountryList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
     );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createToastNotificationSuccess = (toastMesssage: string, toastIcon: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.t(`${toastMesssage}`)}</div>
        <a href="#" data-test-id="undoApi" className="toast-link">
          {this.t("undo")}
        </a>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={toastIcon} alt="emptydata"/>,
      }
    );
  };

  createToastNotification = (toastMesssage: string) => {
    toast.error(this.t(`${toastMesssage}`), {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };

  createToastNotificationSuccesss = (toastMesssage: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.t(`${toastMesssage}`)}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER
      }
    );
  };

  onEditContact = (id:any) => {
    this.setState({
      isEditingContact: true
    }, () => this.getSingleContactData(id))
    
  }

  onCloseEditContact = () => {
    this.setState({isShowEditContact: false, isEditingContact: false})
    this.getSingleGroup()
  }

  handleOpenContactPopover = (event: React.MouseEvent<HTMLButtonElement> | null,id:any) => {
    this.setState({groupContactId:id})
    if (event) {
      this.setState({ contactAnchor: event.target, selectedRowId: id });
      this.getSingleContactData(id)
    }
  };

  handleCloseContactPopover = () => {
    this.setState({ contactAnchor: false });
  };

  handleOpenDeal = (
    event: React.MouseEvent<HTMLButtonElement> | null
  ) => {
    if (event) {
      this.setState({
        anchorDeal: event.currentTarget,
        createDealPopoverOpen: true,
      });
    }
  };

  handleCloseDeal = () => {
    this.setState({ anchorDeal: null});
    this.getSingleGroup();
  };
  handleSelectContactsOpen=()=>{
    this.setState({selectContacts:true})
  }
  handleSelectContactsClose=()=>{
    this.setState({selectContacts:false})
  }
  handleShowContactGroup = () => {
    this.getSingleContactData(this.state.groupContactId)
    this.setState({
      isShowContactGroup: true,
    });
  };

  handleCloseContactGroup = () => {
    this.setState({
      isShowContactGroup: false,
      groupContactId:""
    });
  };

  handleSearchGroup = (text: string) => {
    this.setState({ searchGroupKeyword: text });
  };

  handleOpenCreateContact = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      createContactPopoverShown: true,
    });
  };

  handleCloseCreateContact = () => {
    this.setState({ anchorEl: null });
  };

  openFloatCreateContact = () => {
    this.setState({
      floatCreateContact: true,
      createContactPopoverShown: false,
    });
  };

  closeFloatCreateContact = () => {
    this.setState({ floatCreateContact: false });
  };

  openCreateContactPopoverShown = () => {
    this.setState({ createContactPopoverShown: true });
  };

  closeCreateContactPopoverShown = () => {
    this.setState({ createContactPopoverShown: false });
  };

  handleSearch = (text: string) => {
    this.setState({ searchKeyword: text });
  };

  getContactList = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = configJSON.viewContactsPath;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactListRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSingleGroup = () => {
    const id=localStorage.getItem("viewGroupId")
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = `${configJSON.viewGroupPath}/${id}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleGroupRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSingleContactData = (id:any) => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };

    let endPoint = `${configJSON.viewContactsPath}/${id}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleContacDetailRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({isLoading : true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleOpnAddContactMenu=()=>{
    this.setState({openAddContcatMenu:true})
  }
  handleCloseAddContactMenu=()=>{
    this.setState({openAddContcatMenu:false})
  }
  handleBackBtn=()=>{
    window.location.href="/groups"
    localStorage.removeItem("viewGroupId")
  }
  getAllGroups = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = configJSON.groups;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsRequestApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  addToGroupAction = () => {  
    let group_id = localStorage.getItem("addGroupId"); 
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    let endPoint = `${configJSON.addToGroup}?contact_id=${this.state.groupContactId}&group_id=${group_id}`;
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addtogroupRequestApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDeleteOpen=()=>{
    this.setState({
      isDelete:true, 
      deleteContactDetails: {
        firstName:this.state.contactDetails.attributes.first_name,
        lastName: this.state.contactDetails.attributes.last_name,
        id: this.state.contactDetails.attributes.id,
    },
   })
  }

  handleDeleteClose=()=>{
    this.setState({
      isDelete:false,
      deleteContactDetails: {
        firstName: "",
        lastName: "",
        id: "",
      },
    });
    this.getSingleGroup();
  }

  deleteContactFromGroupAction = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteContactGroupAPIID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewContactsPath}/${this.state.groupContactId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleRenameGroupOpen = () => {
    this.setState({
      isRemoveGroup:true,
      renameContactDetails: {
        firstName:this.state.contactDetails.attributes.first_name,
        lastName: this.state.contactDetails.attributes.last_name,
        id: this.state.contactDetails.attributes.id,
        groupName:this.state.groupDetails.attributes.name
    },
    })
  }
  handleRenameGroupClose = () =>{
    this.setState({
      isRemoveGroup:false,
      renameContactDetails: {
        firstName: "",
        lastName: "",
        id: "",
        groupName:""
      },
    })
  }
  removefromGroupAction = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    let endpoint=`${configJSON.removeContactPath}?contact_id=${this.state.groupContactId}&id=${this.state.groupDetails.id}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeContactGroupAPIID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  onChangeSort = (text:any) => {
    this.setState({ sortValue: text , isSortFilter:true, sortType : "group" }, () => {
      this.getSortAndFilterAction(); 
    });
   };

   handleInputChange = (event: any) => {
    const { name, value } = event.target;  
    this.setState((prevState:any) => ({
      ...prevState,
      [name]: value,
      isSortFilter: true,
    }), () => {
      this.getSortAndFilterAction();
    });
  };
  
   handleChecked = (event:any) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
   getSortAndFilterAction = () => {
    const header = {
      token: this.userToken.meta.token,
    };
    let sortValue;
    if(this.state.sortType === "contact"){
      sortValue = this.state.sortContactValue
    }else if(this.state.sortType === "group"){
      sortValue = this.state.sortValue
    }
  
    let endpoint =`${configJSON.viewContactsPath}?first_name=${sortValue == "A-Z"?"asc":"desc"}&shipping_country=${this.state.filterShippingCountry}&company=${this.state.filterCompany}&desired_category=${this.state.filterBusinessCategories}&group=${localStorage.getItem("viewGroupId")}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSortFilterRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({isLoading: true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getBusinessCategoryList = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessCategoryListRequestApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.businessCategory
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleOpenMoreMenu = (event: any) => {
    if (event) {
      this.setState({ anchorElMore: event.target });
    }
  };
  handleCloseMoreMenu = () => {
    this.setState({ anchorElMore: null });
  };
  handleOpenAddAnExisting = () => {
    this.setState({ isAddAnExisting: true });
  };
  handleCloseAddAnExisting  = () => {
    this.setState({ isAddAnExisting: false });
  };
  handleSearchContact = (text: string) => {
    this.setState({ searchContactKeyword: text, isContactSearching:true  },()=>{
      this.getContactSearchtList()
    });
  };
  handleSortContact = (text:any) => {
    this.setState({ sortContactValue: text , isSorting:true, sortType : "contact" }, () => {
      this.getSortAndFilterAction(); 
    });
   };
  getContactSearchtList = () => {
    const header = {
      token: this.userToken.meta.token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getSearchContctsRequestId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchApiPath}?search_term=${this.state.searchContactKeyword}`,
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.setState({isLoading: true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  toggleShowGroups = () => {
    this.setState({ showMoreGroups: !this.state.showMoreGroups });
  };
   getAddExistingContcatList = () => {
    let group_id = localStorage.getItem("viewGroupId"); 
    const header = {
      token: this.userToken.meta.token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAddAnExistingRequestId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.nonExistingGroupPath}?group_id=${group_id}`,
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.setState({isLoading: true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAddExistingContcat = () => {
    let group_id = localStorage.getItem("viewGroupId");
    let contact_idsString = localStorage.getItem("contactIds");  
    let contact_ids = contact_idsString ? JSON.parse(contact_idsString.replace(/\\/g, '')) : [];

    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };
    const body = {
    "group_id": group_id,
    "contact_ids": contact_ids,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAddAnExistingContactRequestId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AddExistingContactPath}`,
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    this.setState({isLoading: true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleOpenGroup=()=>{
    this.setState({
      openGroup: true,
    });
  }

  handleCloseGroup=()=>{
    this.setState({openGroup:false})
  }
}
// Customizable Area End
