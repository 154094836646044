import React from "react";
import { otherProductImg ,landIcon,vesselIcon,chemicalIcon,phytoIcon,fertilizerIcon,machineryIcon,hardwareIcon,wineIcon,beerIcon,spiritIcon} from "../../blocks/inventorymanagement/src/assets";
import  { Box} from "@material-ui/core";
import { etohSupportImg, externalImage } from "../../blocks/LiveChat2/src/assets";
import { toast } from "react-toastify";
import CloseIcon from '@material-ui/icons/Close';
const baseConfig = require("../../framework/src/config");
import moment from "moment";
import { mp4Default, productImg, mp3Default, pauseRound, playRound, greenCheck, grayCheck} from "../../blocks/TaskList/src/assets";
import {warningIcon} from './assets';
interface ItemDetails {
    category: number;
    subCategory: number;
    targetName: string;
    brandName: string;
    materialName: string;
    colorName: string;
    style: string;
    appellation: string;
    grapeVariety: string;
    vintage: string;
    volume: string;
    area: string;
    unitOfMeasurement: string;
    quantity_unit: string;
    stock_qty: number;
    itemName: string;
    price:number;
    dosage:string;
  }
export const getImageForSubCategory = (subCategory:any, attributes:any , isSendToContact?:boolean) => {
    if (attributes.images?.length > 0) {
        return isSendToContact ? <img src={attributes.images[0].url} className="categoryImg" style={{ height: "68px",
          width: "68px"
      }} /> : <img src={attributes.images[0].url} className="categoryImg" />
    }
    switch (subCategory) {
        case 46:
            return <img src={otherProductImg} className="categoryImg" />;
        case 51:
            return <img src={landIcon} className="categoryImg" />;
        case 52:
            return <img src={vesselIcon} className="categoryImg" />;
        case 43:
            return <img src={chemicalIcon} className="categoryImg" />;
        case 50:
            return <img src={phytoIcon} className="categoryImg" />;
        case 49:
            return <img src={fertilizerIcon} className="categoryImg" />;
        case 47:
            return <img src={machineryIcon} className="categoryImg" />;
        case 48:
            return <img src={hardwareIcon} className="categoryImg" />;
        case 38:
            return <img src={wineIcon} className="categoryImg" />;
        case 42:
            return <img src={beerIcon} className="categoryImg" />;
        case 45:
            return <img src={spiritIcon} className="categoryImg" />;
        default:
            return <img src={spiritIcon} className="categoryImg" />;
    }
};
export function extractItemDetails(item : any) : ItemDetails{
    const attributes = item.attributes;

    const category = attributes.category.id;
    const subCategory = attributes.sub_category.id;

    return {
        category: attributes.category.id,
        subCategory: attributes.sub_category.id,
        targetName: attributes.target?.name ?? "",
        brandName: getBrandName(category, attributes),
        materialName: attributes.meta_data?.material ?? "",
        colorName: getColorName(category, subCategory, attributes),
        style: getStyleName(category, subCategory, attributes),
        appellation: getAppelation(category, subCategory, attributes),
        grapeVariety: subCategory === 38 ? attributes.meta_data?.grape_variety ?? "" : "",
        vintage: attributes.meta_data?.vintage ?? "",
        volume: attributes.meta_data?.volume ?? "",
        area: attributes.meta_data?.area ?? "",
        unitOfMeasurement: attributes.meta_data?.area_unit ?? "",
        quantity_unit: attributes.meta_data?.quantity_unit ?? "",
        stock_qty: attributes.stock_qty ?? 0,
        itemName: attributes.name?.trim(),
        price: attributes?.prices[0]?.amount,
        dosage: attributes.meta_data?.max_dosage,
    };
}

export function getBrandName(category:any, attributes:any) {
    return category === 6 ? "" : attributes.brand?.name ?? "";
}

export function getColorName(category:any, subCategory:any, attributes:any) {
    return subCategory === 42 || subCategory === 45 || category === 7
        ? attributes.meta_data?.color ?? ""
        : "";
}

export function getStyleName(category:any, subCategory:any, attributes:any) {
    return subCategory === 42 || subCategory === 45
        ? attributes.location?.style ?? ""
        : "";
}

export function getAppelation(category:any, subCategory:any, attributes:any) {
    return !(subCategory === 42 || subCategory === 45)
        ? attributes.location?.appellation ?? ""
        : "";
}

export function shouldShowStyleColor(category:any, subCategory:any) {
    return category === 6 && (subCategory === 42 || subCategory === 45);
}

export function shouldShowBrandOnTop(category:any, subCategory:any, brandName:any) {
    return category === 6 && subCategory === 46 ? brandName : "";
}

export function shouldShowVolume(subCategory:any) {
    return subCategory === 52;
}

export function getCurrentTime ()  {
  const now = new Date();
 // now.setUTCHours(now.getUTCHours() + 5);
 // now.setUTCMinutes(now.getUTCMinutes() + 30);
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0');
  const day = String(now.getUTCDate()).padStart(2, '0');
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');
  const formattedTimestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  return formattedTimestamp;
}
export function formatTime(totalSeconds:any) {
  const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = seconds.toFixed(0).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const convertToFiles = async (items:any) => {
    const fileArray:any = [];
  
    await Promise.all(
      items.map(async (item:any) => {
        if (item.url) {
          const response = await fetch(item.url);
          const blob = await response.blob();
          const file = new File([blob], item.filename, { type: item.content_type });
          fileArray.push(file);
        } else if (item instanceof File) {
          fileArray.push(item);
        }
      })
    );
    return fileArray;
  };
  
  export const formatDate = (date:any) => {
    return date ? new Date(date.format("YYYY-MM-DD")).toISOString().slice(0, 10) : "";
  }
  
  export const processDates = (dateArray:any) => {
    if (!dateArray || dateArray.length === 0) return ["", ""];
    return [formatDate(dateArray[0]), formatDate(dateArray[1]) || formatDate(dateArray[0])];
  }

  export const showLettersFromName = (fullName: string) => {
    const splitName = fullName.split(" ")
    if (splitName.length >= 2) {
      // Full name is available
      return (
        splitName[0].charAt(0).toUpperCase() + splitName[1].charAt(0).toUpperCase()
      );
    } else if (splitName.length === 1) {
      // Only first name is available
      return splitName[0].charAt(0).toUpperCase();
    } else {
      // No name is available
      return ""; // or handle this case as per your requirement
    }
  
    
  }

  export const directEmailCount = async (url:string, userToken: string) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: userToken
        },
      });
  
      const result = await response.json();
      return result.data.length;
    } catch (error) {
      console.error(error);
      return undefined; // or handle the error as needed
    }
  }

  export const renderMedia = (mediaFile: string, mediaType: string) => {
    console.log("test data21",mediaFile,mediaType)
    switch (mediaType) {
      case "image":
        return (
          <Box key={mediaFile} className="image-video-audio-preview">
            <img src={mediaFile} alt="file preview" className="ss-image" style={{width: "186px",
            height: "158px",borderRadius: "12px"}}/>
          </Box>
        );
      case "audio":
        return (
          <Box key={mediaFile} className="image-video-audio-preview">
            <audio src={mediaFile} controls className="ss-image" style={{width: "186px",
           borderRadius: "12px"}}/>
          </Box>
        );
      case "video":
        return (
          <Box key={mediaFile} className="image-video-audio-preview">
            <video src={mediaFile} controls className="ss-image" style={{width: "186px",
            height: "158px",borderRadius: "12px", objectFit: "cover"}}/>
          </Box>
        );
        case "application":
          return (
            <Box key={mediaFile} className="image-video-audio-preview">
              <object data={mediaFile} type="application/pdf" style={{width: "186px",
              height: "158px",borderRadius: "12px"}}>
               <p>Your browser does not support PDFs. <a href={mediaFile}>Download the PDF</a>.</p>
               </object>
            </Box>
          );        
      default:
        return (
          <Box key={mediaFile} className="image-video-audio-preview">
            <img src={mediaFile} alt="No Preview" className="preview-image" style={{width: "186px",
            height: "158px",borderRadius: "12px"}} />
          </Box>
        );
    }
  };

  export const renderContent = (type:string,initials:string) => {
    switch (type) {
      case "external":
        return  <Box className="etoh-name">
        <img src={externalImage} alt="brows" />
      </Box>
      case "internal":
        return  <Box className="etoh-name">{initials}</Box>;
      default:
        return <Box className="etoh-name"><img src={etohSupportImg} alt="internal" /></Box>
    }
  };

  export const getPlaceholderImage = (fileType:string) => {
    if (fileType.includes('image')) {
      return <Box className="upload-img">                     
      <img src={productImg} alt="productImg" />
    </Box>;
    } else if (fileType.includes('video')) {
      return <Box className="upload-img">                     
      <img src={mp4Default} alt="productImg" />
    </Box>;
    } else if (fileType.includes('audio')) {
      return <Box className="upload-img">                     
      <img src={mp3Default} alt="productImg" />
    </Box>;
    } else if (fileType.includes('application/pdf')) {
      return <Box className="upload-img">                     
      <img src={mp4Default} alt="productImg" />
    </Box>;
    } else {
      return <Box className="upload-img">                     
      <img src={mp4Default} alt="productImg" />
    </Box>;
    }   
  };

  export const  formatChatDate = (dateString:string) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit' };
    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();
  
    if (isToday) {
      let timeString = date.toLocaleTimeString([], options);
      timeString = timeString.replace('AM', 'am').replace('PM', 'pm');
      return timeString;
    } else if (isYesterday) {
      return "Yesterday";
    } else {
      return date.toLocaleDateString();
    }
  }
  
  export const formatLiveChatTime = (dateString:string) => {
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', options);
  }

  export const createCommonToastNotification = (toastMesssage: string,toastIcon?: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>      
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        icon: toastIcon ? () => <img src={toastIcon} alt="icon" /> : undefined,
        closeButton: ({ closeToast }) => <div className="toast-notification">
        <div        
          className="toast-link"
          data-test-id="undo-toast-link"
        >
         <CloseIcon style={{color:"black"}} onClick={closeToast} />
        </div>
      </div> 
      }
    );
  };
  export const createCommonToastError = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
      icon: () => <img src={warningIcon} alt="emptydata" />,
      autoClose: 100
    }
  )
  };
  export const  exportCSVInventory = async(endPoint: string, token: string, paramType: string)  => {
    let filename = `Export_Inventory_${paramType}_${moment(new Date()).format("YYYYMMDD")}.csv`;
  let url = baseConfig.baseURL +
  endPoint + `file_type=${paramType}`;
  fetch(url, {
    method: "GET",
    headers: { token: token },
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success( <div className="toast-notification">
      <div className="notification-txt">CSV downloaded successfully</div>
    </div>,
    {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
     
    })
    .catch((error) => {
      toast.error( <div className="toast-notification">
      <div className="notification-txt">CSV downloaded successfully</div>
    </div>,
    {
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
    });
  }

  export const showMessageBasedOnRole = (userRole: string) => {
    if(userRole === "employee"){
      return  <Box component="p" className="sub-txt">
      View all teams and members.
     </Box>
    }else if(userRole === "manager"){
      return <Box component="p" className="sub-txt">
      View your teams and manage the members
    </Box>
    }
    else{
      return <Box component="p" className="sub-txt">
      Manage your teams or add new members
    </Box>
    }
  }

  export const restrictKeys = (event: any) => {
    const restrictedKeys = ["e", "E", "-", "+", ".", ","];
    if (restrictedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  export const  hoursToSeconds =  (hours:any) => {
    const [h, m, s] = hours.split(':').map(Number);
    const totalSeconds = (h * 3600) + (m * 60) + (s || 0);
    return totalSeconds;   
  }
 
export function formatTimeTaskHours(totalSeconds: any) {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export function renderTimerIcon(
  taskId: string,
  status: string,
  isTimerRunning: boolean,
  startTaskTimerAPI: (taskId: string, actionType: string) => void
) {
  if (status === "completed") {
    return <img src={playRound} alt="start" />;
  }

  return (
    <img
      src={isTimerRunning ? pauseRound : playRound}
      alt={isTimerRunning ? "pause" : "start"}
      onClick={() =>
        startTaskTimerAPI(taskId, isTimerRunning ? "stopped" : "started")
      }
    />
  );
}
export function renderTaskCount(tasks: any[], translate: (key: string) => string, config: { txtTask: string; txtTasks: string }) {
  const taskCount = tasks.length;
  const label = taskCount > 1 ? translate(config.txtTasks) : translate(config.txtTask);

  return `${taskCount} ${label}`;
}

export function renderStatusIcon(status: string) {
  return status === "completed" ? (
    <img src={greenCheck} alt="check" />
  ) : (
    <img src={grayCheck} alt="check" />
  );
}

export function formatDueDate(date: string | null | undefined): string {
  return date ? moment(date).format("MMM DD, YYYY") : "";
}

export const groupFormats = (groups: any) => {
  const displayedGroups = groups?.slice(0, 4);
      const filteredGroups = displayedGroups?.filter((group:any) => group !== null); 
      const groupNames = filteredGroups?.map((group: any) => group?.name).join(', ');
      const ellipsis = groups?.length > 3 ? '...' : '';

  return `${groupNames} ${ellipsis}`
}

export const appendFileToFormData = (formData:any, catalogueId:string, index:number, fileImg:any) => {
  if (catalogueId !== "") {
    formData.append(`images[${index}][data]`, fileImg);
  } else {
    formData.append(`images[]`, fileImg);
  }
};